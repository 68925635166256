import { useUserStore } from '@/stores/user';

export default defineNuxtRouteMiddleware(async (context) => {
  const { query } = context;

  const userStore = useUserStore();
  const redirect = query.redirect === 'true';
  await userStore.fetchUser(redirect);

  if (userStore.loggedIn) {
    return navigateTo('/games');
  }
});
